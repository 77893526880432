var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "600" } },
    [
      _c(
        "v-card",
        { attrs: { color: "#fff" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "#1C3144" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v("All Invoices")]),
              _c("v-spacer")
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0" },
            [
              _c(
                "section",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "background-color": "#fff",
                              dense: "",
                              outlined: "",
                              label: "Billing Begin Date",
                              autocomplete: "off",
                              "prepend-inner-icon": "mdi-calendar",
                              clearable: ""
                            },
                            on: {
                              "click:clear": function($event) {
                                _vm.billingStartDate = null
                              }
                            },
                            model: {
                              value: _vm.billingStartDate,
                              callback: function($$v) {
                                _vm.billingStartDate = $$v
                              },
                              expression: "billingStartDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "background-color": "#fff",
                              dense: "",
                              outlined: "",
                              label: "Billing End Date",
                              autocomplete: "off",
                              "prepend-inner-icon": "mdi-calendar",
                              clearable: ""
                            },
                            on: {
                              "click:clear": function($event) {
                                _vm.billingEndDate = null
                              }
                            },
                            model: {
                              value: _vm.billingEndDate,
                              callback: function($$v) {
                                _vm.billingEndDate = $$v
                              },
                              expression: "billingEndDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "section",
                { staticClass: "pa-4", staticStyle: { background: "#fafafa" } },
                [
                  _c(
                    "v-responsive",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              label: "Billing Types",
                              "return-object": "",
                              multiple: "",
                              chips: "",
                              items: _vm.billingTypes,
                              "item-text": "name",
                              "item-value": "value"
                            },
                            model: {
                              value: _vm.selected,
                              callback: function($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-toolbar",
        {
          staticStyle: { border: "# 2px solid ccc" },
          attrs: { flat: "", color: "#fafafa" }
        },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "white--text",
              attrs: { disabled: _vm.selected.length < 1, color: "#00B24A" },
              on: { click: _vm.createInvoices }
            },
            [
              _vm._v(" Create Invoices"),
              _c("v-icon", { staticClass: "ml-1", attrs: { dense: "" } }, [
                _vm._v("mdi-plus")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }