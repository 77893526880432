import { invoicing } from './invoicing'
// import { Auth } from 'aws-amplify'
import { store } from '@/store'

export default class endpoints {
  static async getManualInvoices (invoiceID) {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: `/invoice/manual_invoice/${invoiceID}`,
      method: 'GET'
    })
  }

  static async getBatches (status) {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: `/invoice/batch/${status}`,
      method: 'GET'
    })
  }

  static async createBatch (invoice) {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: '/invoice/create_batch',
      method: 'POST',
      data: invoice
    })
  }

  static async restartBatch (batchNumber) {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: `/invoice/reprocess_batch/${batchNumber}`,
      method: 'POST'
    })
  }

  static async deleteBatch (batchNumber) {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: `/invoice/batch/${batchNumber}`,
      method: 'DELETE'
    })
  }

  static async getBatchInvoices (batchNumber) {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: `/invoice/batch_invoices/${batchNumber}`,
      method: 'GET'
    })
  }

  static async getInvoiceItems (batchNumber, invoiceNumber) {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: `/invoice/items/${batchNumber}/${invoiceNumber}`,
      method: 'GET'
    })
  }

  static async getInvoicePayments (invoiceNumber) {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: `/payments/${invoiceNumber}`,
      method: 'GET'
    })
  }

  static async processManualInvoice (manualInvoice) {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: '/invoice/manual_invoice',
      method: 'POST',
      data: manualInvoice
    })
  }

  static async postInvoice (batchNumber) {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: `/invoice/post_batch/${batchNumber}`,
      method: 'POST'
    })
  }

  static async getRecapAdditions (start, end, filters) {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: `/recap_additions/${start}/${end}?${filters}`,
      method: 'GET'
    })
  }

  static async getRecapComponents () {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: '/recap_components',
      method: 'GET'
    })
  }

  static async saveRecapAddition (payload) {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: '/recap_additions',
      method: 'POST',
      data: payload
    })
  }

  static async updateRecapAddition (payload) {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: '/recap_additions',
      method: 'PUT',
      data: payload
    })
  }

  static async deleteRecapAddition (payload) {
    invoicing.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return invoicing({
      url: '/recap_additions',
      method: 'DELETE',
      data: payload
    })
  }
}
