<template>
  <v-dialog :value="value" persistent width="600">
    <v-card color="#fff">
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>All Invoices</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container class="ma-0">
        <section class="pa-4">
          <v-row dense justify="center">
            <v-col cols="5">
              <v-text-field background-color="#fff" dense outlined label="Billing Begin Date" v-model="billingStartDate"
                autocomplete="off" prepend-inner-icon="mdi-calendar" clearable @click:clear="billingStartDate = null">
              </v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field background-color="#fff" dense outlined label="Billing End Date" v-model="billingEndDate"
                autocomplete="off" prepend-inner-icon="mdi-calendar" clearable @click:clear="billingEndDate = null">
              </v-text-field>
            </v-col>
          </v-row>
        </section>
        <v-divider/>
        <section class="pa-4" style="background:#fafafa;">
          <v-responsive>
            <v-col>
              <v-autocomplete outlined dense background-color="#fff" label="Billing Types" return-object
                multiple chips :items="billingTypes" item-text="name" item-value="value" v-model="selected">
              </v-autocomplete>
            </v-col>
          </v-responsive>
        </section>
      </v-container>
    </v-card>
    <v-divider />
    <v-toolbar flat color="#fafafa" style="border:# 2px solid ccc;">
      <v-spacer />
      <v-btn :disabled="selected.length < 1" color="#00B24A" class="white--text" @click="createInvoices">
        Create Invoices<v-icon class="ml-1" dense>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import invoicing from '@/axios/invoicing/endpoints'
import { notification } from '@/mixins/notification'

export default {
  name: 'InvoiceAllTypes',

  mixins: [notification],

  props: ['value', 'getBatches'],

  data () {
    return {
      billingStartDate: '',
      billingEndDate: '',
      selected: []
    }
  },

  computed: {
    tabs () {
      return [
        { name: 'All Invoicing', content: 'InvoiceAll', tab: 'All' },
        { name: 'Manual Invoice', content: 'InvoiceManual', tab: 'Manual' }
      ]
    },

    billingTypes () {
      return [
        { name: 'Grocery', value: 'Grocery' },
        { name: 'Meat Accrual', value: 'MeatAccrual' },
        { name: 'Perishables', value: 'Meat' },
        { name: 'Ad Scans', value: 'AdScans' },
        { name: 'TPR Scans', value: 'TPRScans' },
        { name: 'VMC Billbacks', value: 'VMC_BB' },
        { name: 'VMC Scans', value: 'VMC_Scans' }
      ]
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.billingStartDate = moment().startOf('isoweek').subtract(22, 'days').format('M/D/YYYY')
          this.billingEndDate = moment(this.billingStartDate).add(6, 'days').format('M/D/YYYY')
        }
      }
    }
  },

  methods: {
    getDates () {
      this.billingStartDate = moment().startOf('isoweek').subtract(22, 'days').format('M/D/YYYY')
      this.billingEndDate = moment(this.billingStartDate).add(6, 'days').format('M/D/YYYY')
    },

    allowedBegin: val => ![0, 1, 2, 3, 4, 5].includes(new Date(val).getDay()),

    async createInvoices () {
      const errors = []
      this.showLoader('Processing')
      for (let i = 0; i < this.selected.length; i++) {
        const billingTypeObj = {
          begin_date: moment(this.billingStartDate).format('YYYY-MM-DD'),
          end_date: moment(this.billingEndDate).format('YYYY-MM-DD'),
          billing_type: this.selected[i].value
        }
        await invoicing.createBatch(billingTypeObj)
          .then().catch(() => {
            errors.push(`Error Creating ${this.selected[i].value} Invoice`)
          })
      }
      this.$emit('update:value', false)
      this.notify('success', 'Billing Types Submitted')
      this.getBatches()
    }
  }
}
</script>
